// external
import React, { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';

// components

// store
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';

// hooks
import { useLocalization, useSessions } from 'hooks';

// types
import { useProfiles, useRelationships } from '@guider-global/front-end-hooks';
import {
  getPastSessionStartAndEndDates,
  getSessionTitle,
} from '@guider-global/isomorphic-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  IRelationship,
  TConvertableSessionLogTime,
  TimeZone,
} from '@guider-global/shared-types';
import { Checkbox, Stack, theme } from '@guider-global/ui';
import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { ModalCard } from 'components';
import { IButtonAction } from 'components/ActionButton';
import { deepMerge } from 'utils';

export interface ISessionLogInputs {
  title: string;
  date: Date;
  startTime: string;
  endTime: string;
}

interface IRelationshipSessionsLogProps {
  relationship: IRelationship;
  handleClose: () => void;
}

interface IDateCheckbox {
  heading: string | undefined;
  name: TConvertableSessionLogTime;
}

export const RelationshipSessionsLog: React.FC<
  IRelationshipSessionsLogProps
> = ({ relationship, handleClose }) => {
  const [searchParams] = useSearchParams();
  const day = (searchParams.get('day') ?? '0') as
    | TConvertableSessionLogTime
    | undefined;

  const [checkedDate, setCheckedDate] = useState<
    TConvertableSessionLogTime | undefined
  >(day);

  //hooks
  const dispatch = useAppDispatch();
  const organizationSlug = getSubDomain();
  const { reqRelationships, isLoadingRelationships } = useRelationships({});

  //baseLanguage
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const pastSessionsLabels =
    baseLanguage?.relationships?.sessions?.log_past_session_modal;

  const checkboxes: IDateCheckbox[] = [
    {
      heading: pastSessionsLabels?.today_checkbox_label,
      name: 'today',
    },
    {
      heading: pastSessionsLabels?.this_week_checkbox_label,
      name: 'this-week',
    },
    {
      heading: pastSessionsLabels?.this_month_checkbox_label,
      name: 'this-month',
    },
    {
      heading: pastSessionsLabels?.last_month_checkbox_label,
      name: 'last-month',
    },
  ];

  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  //form
  const { handleSubmit } = useForm<ISessionLogInputs>({ mode: 'onChange' });

  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);
  const guideProfiles = relationship.guideProfiles ?? [];
  const traineeProfiles = relationship.traineeProfiles ?? [];

  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('md'));

  //sessions
  const { reqSessions, isLoadingSessions } = useSessions({});

  const programSlug = relationship?.programSlug;

  const { programs } = useOrganizationPrograms({ organizationSlug });
  const program = programs?.find(
    (program) => program.metadata.id.current === programSlug,
  );

  const programName = program?.metadata.program_name;

  const onSubmit: SubmitHandler<ISessionLogInputs> = async (data) => {
    if (!checkedDate) {
      return;
    }

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions() as {
      timeZone?: TimeZone | string;
    };

    const selectedDate = checkboxes?.find(
      (checkbox) => checkbox.name === checkedDate,
    );

    if (!selectedDate) {
      return;
    }

    const pastSessionDates = getPastSessionStartAndEndDates(selectedDate?.name);

    const sessionsResponse = await reqSessions({
      method: 'POST',
      url: '/sessions',
      data: {
        name: programName
          ? getSessionTitle(relationship, programName ?? '')
          : 'Logged',
        organizationSlug,
        programSlug,
        relationshipId: relationship.id,
        description: '',
        participantProfiles: [
          ...traineeProfiles.map((trainee) => trainee.id ?? ''),
          ...guideProfiles.map((guide) => guide.id ?? ''),
        ],
        start: pastSessionDates.start,
        end: pastSessionDates.end,
        timezone: timeZone,
        ownerProfile: profile?.id,
        pastSession: true,
      },
    });

    if (sessionsResponse.status !== 'success') {
      dispatch(
        showAppAlert({
          severity: 'error',
          message:
            baseLanguage?.relationships?.sessions?.log_past_session_modal
              ?.log_session_error_alert_text,
          timeout: 5000,
        }),
      );
    } else {
      await Promise.all([
        reqSessions({ url: '/sessions' }),
        reqRelationships({ url: '/relationships' }),
      ]);

      dispatch(
        showAppAlert({
          severity: 'success',
          message:
            baseLanguage?.relationships?.sessions?.log_past_session_modal
              ?.log_session_success_alert_text,
          timeout: 5000,
        }),
      );

      handleClose();
    }
  };

  const modalActions: IButtonAction | IButtonAction[] = [
    {
      label: baseLanguage?.globals?.common?.cancel_button_label,
      color: 'info',
      variant: 'outlined',
      action: handleClose,
      dataCyLabel: 'relationship-log-session-modal-cancel-button',
    },
    {
      label: baseLanguage?.globals?.sessions?.log_session_button_label,
      variant: 'contained',
      color: 'info',
      action: handleSubmit(onSubmit),
      isLoadingButton: true,
      disabled: !checkedDate,
      dataCyLabel: 'relationship-log-session-modal-log-session-button',
    },
  ];

  return (
    <ModalCard
      title={
        baseLanguage?.relationships?.sessions?.log_past_session_modal?.title
      }
      description={
        baseLanguage?.relationships?.sessions?.log_past_session_modal
          ?.description
      }
      actions={modalActions}
      handleClose={handleClose}
      isLoading={isLoadingSessions || isLoadingRelationships}
      data-cy="relationship-log-session-modal"
      closeIconButtonDataCy="relationship-log-session-modal-close-icon-button"
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={2}
        justifyContent={'space-between'}
      >
        <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
          {checkboxes.map((checkbox) => (
            <Checkbox
              key={checkbox.name}
              heading={checkbox.heading}
              variant="option"
              onChange={() => setCheckedDate(checkbox.name)}
              isChecked={checkedDate === checkbox.name}
              data-cy={`relationship-log-session-modal-${checkbox.name}-button`}
            />
          ))}
        </ThemeProvider>
      </Stack>
    </ModalCard>
  );
};
