import { IProfile, IRelationship } from '@guider-global/shared-types';
import { Box } from '@mui/material';
import { useLocalization, useMixpanelEvents } from 'hooks';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useProfiles, useRelationships } from '@guider-global/front-end-hooks';
import {
  getISOStringWithoutMilliseconds,
  getSubDomain,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { AvatarButton, DropDown, IconButton } from '@guider-global/ui';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import PersonOff from '@mui/icons-material/PersonOff';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import RelationshipsConcludeDialog from './RelationshipsConcludeDialog';

interface RelationshipConcludeProps {
  relationship: IRelationship;
}

const RelationshipConclude: FC<RelationshipConcludeProps> = ({
  relationship,
}) => {
  const organizationSlug = getSubDomain();
  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);
  const profileId = profile?.id;

  const programSlug = relationship?.programSlug;
  const relationshipId = relationship.id;

  const { programs } = useOrganizationPrograms({ organizationSlug });

  const { organization } = useOrganization({ organizationSlug });

  let programType;

  if (programSlug) {
    const program = programs?.find(
      (program) => program.metadata.id.current === programSlug,
    );
    programType = program?.program_details?.program_type;
  } else {
    programType = organization?.open_matching?.program_type;
  }

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const navigate = useNavigate();
  const { pathname } = useLocation();
  // Local state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Redux
  const dispatch = useAppDispatch();

  // Hooks
  const { trackMixpanelEvent } = useMixpanelEvents();
  const guideProfiles = relationship?.guideProfiles as IProfile[];
  const guideProfilesIds = guideProfiles?.map((guide) => guide.id) ?? [];
  const isGroupProgram = relationship?.programVariationTypeSlug === 'group';
  const isGuide = guideProfilesIds.includes(profileId ?? '');

  let heading: string;
  let subtitle: string;
  let dialogTitle: string;
  let dialogDescription: string;
  let cancelButtonLabel =
    baseLanguage?.globals?.common?.cancel_button_label ?? 'Cancel';
  let concludeRelationshipButtonLabel: string;

  const groupGuide =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.conclude_relationship;
  const groupTrainee =
    programType?.program_type_text.variations?.group?.relationships?.trainee
      ?.conclude_relationship;
  const individual =
    programType?.program_type_text.variations?.individual?.relationships
      ?.conclude_relationship;

  // Conclude Group

  if (isGroupProgram) {
    if (isGuide) {
      heading = groupGuide?.button_title ?? '';
      subtitle = groupGuide?.button_description ?? '';
      dialogTitle = groupGuide?.conclude_relationship_modal.title ?? '';
      dialogDescription =
        groupGuide?.conclude_relationship_modal.description ?? '';
      concludeRelationshipButtonLabel = groupGuide?.button_title ?? '';
    } else {
      heading = groupTrainee?.button_title ?? '';
      subtitle = groupTrainee?.button_description ?? '';
      dialogTitle = groupTrainee?.conclude_relationship_modal.title ?? '';
      dialogDescription =
        groupTrainee?.conclude_relationship_modal.description ?? '';
      concludeRelationshipButtonLabel = groupTrainee?.button_title ?? '';
    }
  } else {
    heading = individual?.button_title ?? '';
    subtitle = individual?.button_description ?? '';
    dialogTitle = individual?.conclude_relationship_modal.title ?? '';
    dialogDescription =
      individual?.conclude_relationship_modal.description ?? '';
    concludeRelationshipButtonLabel = individual?.button_title ?? '';
  }

  const getSuccess = () => {
    if (isGroupProgram) {
      return isGuide
        ? groupGuide?.conclude_relationship_modal.success_alert_text ?? ''
        : groupTrainee?.conclude_relationship_modal.success_alert_text ?? '';
    }

    return individual?.conclude_relationship_modal.success_alert_text ?? '';
  };

  const getError = () => {
    if (isGroupProgram) {
      return isGuide
        ? groupGuide?.conclude_relationship_modal.error_alert_text ?? ''
        : groupTrainee?.conclude_relationship_modal.error_alert_text ?? '';
    }

    return individual?.conclude_relationship_modal.error_alert_text ?? '';
  };

  // Relationships
  const { reqRelationships } = useRelationships({});

  // Events
  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    navigate(`/relationships/${relationshipId}`);
  }, [navigate, relationshipId]);
  const handleModalOpen = () => {
    setIsModalOpen(true);
    navigate(`/relationships/${relationshipId}/conclude`);
  };

  const handleConcludeRelationship = async () => {
    let isSuccess = false;

    setIsLoading(true);

    if ((isGroupProgram && isGuide) || !isGroupProgram) {
      const updateRelationship = await reqRelationships({
        method: 'PATCH',
        url: `/relationships/${relationshipId}`,
        data: {
          isConcluded: true,
        },
      });

      isSuccess = updateRelationship.status === 'success';

      if (isSuccess) {
        trackMixpanelEvent('Relationship - Concluded', {
          'Concluded on': getISOStringWithoutMilliseconds(Date.now()),
          Program: programSlug,
          'Concluded by': isGuide ? 'guide' : 'trainee',
        });
      }
    } else {
      const updateRelationship = await reqRelationships({
        method: 'PATCH',
        url: `/relationships/${relationshipId}`,
        data: {
          ...(profileId && { traineeProfiles: [profileId] }),
        },
      });

      isSuccess = updateRelationship.status === 'success';
    }

    dispatch(
      showAppAlert({
        severity: isSuccess ? 'success' : 'error',
        message: isSuccess ? getSuccess() : getError(),
        timeout: 5000,
      }),
    );

    setIsModalOpen(false);

    await Promise.all([reqRelationships({ url: '/relationships' })]);

    navigate('/relationships');

    setIsLoading(false);
  };

  // Derivatives
  const archived = relationship.isConcluded;

  useEffect(() => {
    if (archived) {
      handleModalClose();
      return;
    }

    setIsModalOpen(pathname.endsWith('/conclude'));
  }, [handleModalClose, archived, pathname]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'flex-end',
      }}
    >
      <DropDown
        sx={{ ul: { p: 0 } }}
        anchor={<IconButton variant="regular" icon={<MoreHoriz />} />}
      >
        <AvatarButton
          sx={{ p: 1 }}
          onClick={handleModalOpen}
          orientation="horizontal"
          icon={<PersonOff />}
          size="small"
          heading={heading}
          subtitles={[subtitle]}
          disabled={archived}
        />
      </DropDown>

      <RelationshipsConcludeDialog
        dialogTitle={dialogTitle}
        dialogDescription={dialogDescription}
        cancelButtonLabel={cancelButtonLabel}
        concludeRelationshipButtonLabel={concludeRelationshipButtonLabel}
        open={isModalOpen}
        onClose={handleModalClose}
        onConcludeRelationship={handleConcludeRelationship}
        loading={isLoading}
      />
    </Box>
  );
};

export default RelationshipConclude;
