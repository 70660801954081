// external
import {
  Avatar,
  Box,
  Chip,
  ChipProps,
  Divider,
  Drawer,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import {
  RelationshipGroupAlert,
  RelationshipSessions,
  RelationshipViewProfileModal,
} from 'components';

// types
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  IMembership,
  IRelationship,
  ISkill,
} from '@guider-global/shared-types';

import { useProfileImage } from '@guider-global/azure-storage-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  Button,
  MultiAvatarButton,
  Stack,
  Text,
  hexToRGB,
} from '@guider-global/ui';
import {
  MembershipDataProps,
  ProfilePreviewProfileData,
} from 'components/ProfilePreview';
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { RelationshipGroupMembersModal } from 'modals';

interface IRelationshipDetailsGroupSidebarProps {
  relationship: IRelationship;
  isGroupProgram: boolean;
  isGuide: boolean;
}

export const RelationshipDetailsGroupSidebar: React.FC<
  IRelationshipDetailsGroupSidebarProps
> = ({ relationship, isGroupProgram, isGuide }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const { getProfileImage } = useProfileImage();

  const [selectedUserId, setSelectedUserId] = useState<string>();

  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const [groupMembersModalOpen, setGroupMembersModalOpen] = useState(false);
  const handleGroupMembersModalClose = () => {
    setGroupMembersModalOpen(false);
  };

  const programSlug = relationship?.programSlug;
  const { programs } = useOrganizationPrograms({ organizationSlug });
  const program = programs.find(
    (program) => program.metadata.id.current === programSlug,
  );

  useEffect(() => {
    const programSlug = relationship?.programSlug;
    if (!programSlug || !program) {
      navigate('/relationships');
    }
  }, [navigate, program, relationship?.programSlug]);

  if (!programSlug || !program) {
    return <></>;
  }

  const guideMembership = relationship.guideMemberships?.at(0);
  const isActiveGroup = guideMembership?.isPublished === false;

  const programTypeGuideNoun =
    program?.program_details?.program_type?.program_type_text.common?.noun;

  const skillsConfig = program?.registration?.skills;
  const isSkillsEnabled = skillsConfig?.enable_skills_question;

  const guideMembershipData = relationship?.guideMemberships?.at(0);
  const skills = guideMembershipData?.skills as Partial<ISkill>[];

  const modalTitle = baseLanguage?.relationships?.view_profile_modal?.title;
  const modalCloseButtonLabel =
    baseLanguage?.globals?.common?.close_button_label ?? 'Close';

  const handleProfileModalClose = () => {
    setSelectedUserId(undefined);
    setGroupMembersModalOpen(true);
  };
  const handleProfileModalOpen = (userId?: string) => {
    setSelectedUserId(userId);
    handleGroupMembersModalClose();
  };

  const getSelectedProfileData = (
    selectedUserId?: string,
  ): ProfilePreviewProfileData => {
    const guideProfiles = relationship.guideProfiles ?? [];
    const traineeProfiles = relationship.traineeProfiles ?? [];
    const profiles = [...guideProfiles, ...traineeProfiles];
    const users = relationship.users ?? [];

    const selectedProfile = profiles.find(
      (profile) => profile.userId === selectedUserId,
    );

    const selectedProfileUserId = selectedProfile?.userId;

    const selectedUser = users.find(
      (user) => user.id === selectedProfileUserId,
    );
    return {
      ...selectedProfile,
      pictures: {
        profile: selectedProfile?.picture,
        user: selectedUser?.picture,
      },
    };
  };

  const getSelectedProfileMembershipData = (
    selectedUserId?: string,
  ): MembershipDataProps => {
    const guideMemberships = relationship.guideMemberships ?? [];
    const traineeMemberships = relationship.traineeMemberships ?? [];
    const memberships = [...guideMemberships, ...traineeMemberships];

    const selectedMembership = memberships.find(
      (membership) => membership.userId === selectedUserId,
    );

    const skillFieldSlugs = selectedMembership?.skills?.map((skill) => {
      const skillTyped = skill as ISkill;
      return skillTyped.fieldSlug;
    });
    return {
      skills: skillFieldSlugs ?? [],
      membership: selectedMembership ? [selectedMembership as IMembership] : [],
    };
  };
  const getSelectedRelationshipRole = (selectedUserId?: string) => {
    const guideProfile = relationship.guideProfiles?.find(
      (guideProfile) => guideProfile.userId === selectedUserId,
    );
    if (guideProfile) return 'guide';
    return 'trainee';
  };

  const avatars =
    relationship.users?.map((user) => {
      const userId = user.id;
      const traineeProfile = relationship.traineeProfiles?.find(
        (traineeProfile) => traineeProfile.userId === userId,
      );
      const guideProfile = relationship.guideProfiles?.find(
        (guideProfile) => guideProfile.userId === userId,
      );
      const profilePicture = traineeProfile
        ? traineeProfile.picture
        : guideProfile?.picture;
      const userPicture = user.picture;
      return getProfileImage({
        profilePicture: profilePicture,
        userPicture: userPicture,
      });
    }) ?? [];

  const chipProps: Partial<ChipProps> = {
    size: 'small',
    sx: {
      backgroundColor: `rgba(${hexToRGB(theme.palette.info.main)}, 0.08)`,
      mr: 1,
      mb: 1,
    },
  };

  const programName = program.metadata.program_name;
  return (
    <Drawer
      data-cy="components_relationship_drawer"
      open={true}
      anchor="right"
      variant="permanent"
      sx={{
        height: '100%',
        '& .MuiDrawer-paper': {
          height: '100%',
          overflowY: 'auto',
          width: isMobile ? '100vw' : '320px',
          borderLeft: `1px solid ${theme.palette.divider}`,
          position: isMobile ? 'absolute' : 'static',
          px: 3,
          pb: 3,
        },
      }}
    >
      {isMobile && (
        <>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="body2" sx={{ py: 2 }}>
              {programName}
            </Typography>
            <Box>
              <MultiAvatarButton
                avatarSrcArray={avatars}
                avatarHeight={29}
                avatarWidth={29}
                textSize="xs"
                heading=""
                subtitles={[
                  ((relationship.traineeProfiles?.length ?? 0) + 1).toString(),
                ]}
                sx={{
                  border: '1px solid #E0E0E0',
                  borderRadius: '4px',
                  py: '4px',
                  pl: '4px',
                }}
                onClick={() => setGroupMembersModalOpen(!groupMembersModalOpen)}
              />
            </Box>
          </Stack>
          <Divider />
        </>
      )}
      <RelationshipGroupAlert relationship={relationship} isGuide={isGuide} />
      <RelationshipSessions
        relationship={relationship}
        isGroupProgram={isGroupProgram}
        isActiveGroup={isActiveGroup}
        isGuide={isGuide}
      />
      <Box sx={{ mt: 3 }}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {program.registration?.registration_group?.group_description
            .question_heading ??
            program.registration?.registration_group?.group_description
              .input_label}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontWeight: 400,
            mt: 1,
            color: theme.palette.text.secondary,
            wordBreak: 'break-word',
          }}
        >
          {relationship.description}
        </Typography>
        {isSkillsEnabled && guideMembershipData?.skills && (
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              {skillsConfig.guide.question_heading ??
                skillsConfig?.text?.input_label ??
                ''}
            </Typography>
            <Box
              sx={{
                mt: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              {skills.map((skill, index) => (
                <Chip
                  key={`skills-chip-${skill}-${index}`}
                  label={
                    skillsConfig?.options?.find(
                      (option) => option.id.current === skill.fieldSlug,
                    )?.label
                  }
                  {...chipProps}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>

      {relationship && (
        <RelationshipViewProfileModal
          isOpen={!!selectedUserId}
          handleClose={handleProfileModalClose}
          title={modalTitle}
          closeButtonLabel={modalCloseButtonLabel}
          closeButtonAction={handleProfileModalClose}
          programSlug={relationship.programSlug}
          userRole={getSelectedRelationshipRole(selectedUserId)}
          profileData={getSelectedProfileData(selectedUserId)}
          membershipData={getSelectedProfileMembershipData(selectedUserId)}
        />
      )}
      {relationship && (
        <RelationshipGroupMembersModal
          handleClose={handleGroupMembersModalClose}
          isModalOpen={groupMembersModalOpen}
          title={relationship.title ?? ''}
          actions={[
            {
              label: modalCloseButtonLabel,
              action: handleGroupMembersModalClose,
              color: 'info',
              variant: 'contained',
            },
          ]}
        >
          {relationship.guideProfiles?.map((guideProfile) => {
            const guideUser = relationship.users?.find(
              (user) => guideProfile.userId,
            );

            return (
              <Button
                key={`${guideProfile.id}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 1.5,
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  '&.MuiButton-root:hover': {
                    backgroundColor: theme.palette.background.default,
                  },
                  py: 1.5,
                  px: 1,
                }}
                variant="text"
                onClick={() => handleProfileModalOpen(guideProfile?.userId)}
              >
                <Avatar
                  src={getProfileImage({
                    profilePicture: guideProfile.picture,
                    userPicture: guideUser?.picture,
                  })}
                  sx={{
                    width: '29px',
                    height: '29px',
                  }}
                  variant="rounded"
                />
                <Text text={guideProfile.displayName} variant="subtitle2" />
                <Chip
                  label={programTypeGuideNoun}
                  sx={{
                    color: theme.palette.info.main,
                    backgroundColor: `rgba(${hexToRGB(
                      theme.palette.info.main,
                    )}, 0.08)`,
                    fontSize: '13px',
                    height: '20px',
                  }}
                />
              </Button>
            );
          })}

          {relationship.traineeProfiles?.map((traineeProfile, index) => {
            const traineeUser = relationship.users?.find(
              (user) => traineeProfile.userId === user.id,
            );
            return (
              <Button
                key={`${traineeProfile.id}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 1.5,
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  py: 1.5,
                  px: 1,
                  width: '100%',
                  '&.MuiButton-root:hover': {
                    backgroundColor: theme.palette.background.default,
                  },
                }}
                variant="text"
                onClick={() => handleProfileModalOpen(traineeProfile.userId)}
              >
                <Avatar
                  src={getProfileImage({
                    profilePicture: traineeProfile.picture,
                    userPicture: traineeUser?.picture,
                  })}
                  sx={{
                    width: '29px',
                    height: '29px',
                  }}
                  variant="rounded"
                />

                <Text text={traineeProfile.displayName} variant="subtitle2" />
              </Button>
            );
          })}
        </RelationshipGroupMembersModal>
      )}
    </Drawer>
  );
};
