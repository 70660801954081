// external
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import Close from '@mui/icons-material/Close';
import MenuOpen from '@mui/icons-material/MenuOpen';
import {
  Avatar,
  Box,
  Chip,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
  useTheme,
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { RelationshipViewProfileModal } from 'components';

// types
import {
  EProgramVariation,
  IMembership,
  IRelationship,
  ISkill,
} from '@guider-global/shared-types';

import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  getLanguageFromCMS,
  getSubDomain,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  Button,
  GroupAvatar,
  IconButton,
  MultiAvatarButton,
  Text,
  theme,
} from '@guider-global/ui';
import {
  MembershipDataProps,
  ProfilePreviewProfileData,
} from 'components/ProfilePreview';
import { interpolate } from 'functions';
import {
  useLocalization,
  useMobileMediaQuery,
  useProfiles,
  useRelationships,
} from 'hooks';
import { RelationshipGroupMembersModal } from 'modals';
import { useAppDispatch } from 'store/hooks';
import { showAppAlert } from 'store/slices/appSlice';
import { deepMerge, hexToRGB } from 'utils';
import RelationshipConclude from '../RelationshipsConclude';

interface IRelationshipHeaderProps {
  relationship: IRelationship;
  otherPartyProfile?: {
    displayName?: string;
    profilePicture?: string;
    userPicture?: string;
  };
  relationshipCreatedAt: Date;
  programName: string;
  roleLabel: string;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
}

export const RelationshipHeader: React.FC<IRelationshipHeaderProps> = ({
  relationship,
  otherPartyProfile,
  relationshipCreatedAt,
  programName,
  roleLabel,
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  const [groupMembersModalOpen, setGroupMembersModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [selectedProfileId, setSelectedProfileId] = useState<string>();

  const isGroup =
    relationship?.programVariationTypeSlug === EProgramVariation.Group;

  const { reqRelationships } = useRelationships({});
  const { getProfiles } = useProfiles({});
  const profile = getProfiles()?.at(0);

  const groupMentor = useMemo(() => {
    const profile = relationship?.guideProfiles?.at(0);
    const user = relationship?.users?.find(
      (user) => user.id === profile?.userId,
    );
    return {
      user,
      profile,
    };
  }, [relationship]);

  const ownRole =
    groupMentor?.profile?.id === profile?.id ? 'guide' : 'trainee';

  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMobile = useMobileMediaQuery();
  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });
  const { getProfileImage } = useProfileImage();

  const programSlug = relationship.programSlug;
  const { programs } = useOrganizationPrograms({ organizationSlug });
  const program = programs.find(
    (program) => program.metadata.id.current === programSlug,
  );

  const programTypeGuideNoun =
    program?.program_details?.program_type?.program_type_text.common?.noun;

  const handleGroupMembersModalClose = () => {
    setGroupMembersModalOpen(false);
  };

  const modalTitle = baseLanguage?.relationships?.view_profile_modal?.title;
  const modalCloseButtonLabel =
    baseLanguage?.globals?.common?.close_button_label;

  const handleProfileModalClose = () => {
    setSelectedUserId(undefined);
    setSelectedProfileId(undefined);
    setGroupMembersModalOpen(true);
  };

  const handleProfileModalOpen = (userId?: string, profileId?: string) => {
    setSelectedUserId(userId);
    setSelectedProfileId(profileId);
    handleGroupMembersModalClose();
  };

  const getSelectedProfileData = (
    selectedUserId?: string,
  ): ProfilePreviewProfileData => {
    const guideProfiles = relationship.guideProfiles ?? [];
    const traineeProfiles = relationship.traineeProfiles ?? [];
    const profiles = [...guideProfiles, ...traineeProfiles];

    const selectedProfile = profiles.find(
      (profile) => profile.userId === selectedUserId,
    );

    const selectedProfileUserId = selectedProfile?.userId;

    const selectedUser = relationship.users?.find(
      (user) => user.id === selectedProfileUserId,
    );
    return {
      ...selectedProfile,
      pictures: {
        profile: selectedProfile?.picture,
        user: selectedUser?.picture,
      },
    };
  };

  const getSelectedProfileMembershipData = (
    selectedUserId?: string,
  ): MembershipDataProps => {
    const guideMemberships = relationship.guideMemberships ?? [];
    const traineeMemberships = relationship.traineeMemberships ?? [];
    const memberships = [...guideMemberships, ...traineeMemberships];

    const selectedMembership = memberships.find(
      (membership) => membership.userId === selectedUserId,
    );

    const skillFieldSlugs = selectedMembership?.skills?.map((skill) => {
      const skillTyped = skill as ISkill;
      return skillTyped.fieldSlug;
    });
    return {
      skills: skillFieldSlugs ?? [],
      membership: selectedMembership ? [selectedMembership as IMembership] : [],
    };
  };

  const getSelectedRelationshipRole = (selectedUserId?: string) => {
    const guideProfile = relationship.guideProfiles?.find(
      (guideProfile) => guideProfile.userId === selectedUserId,
    );
    if (guideProfile) return 'guide';
    return 'trainee';
  };

  const locale = getLanguageFromCMS(baseLanguage?.language_code);

  const avatars =
    relationship.users?.map((user) => {
      const userId = user.id;
      const traineeProfile = relationship.traineeProfiles?.find(
        (traineeProfile) => traineeProfile.userId === userId,
      );
      const guideProfile = relationship.guideProfiles?.find(
        (guideProfile) => guideProfile.userId === userId,
      );
      const profilePicture = traineeProfile
        ? traineeProfile.picture
        : guideProfile?.picture;
      const userPicture = user.picture;
      return getProfileImage({
        profilePicture: profilePicture,
        userPicture: userPicture,
      });
    }) ?? [];

  const renderContent = () => {
    if (isMobile && isSidebarOpen) {
      return (
        <Typography variant="overline" flex={1}>
          {baseLanguage?.relationships?.relationships_list?.title}
        </Typography>
      );
    } else {
      return (
        <Box sx={{ flex: 1 }}>
          {relationship && (
            <>
              <Typography
                sx={{ fontSize: '18px', fontWeight: 600, lineHeight: '120%' }}
              >
                {isGroup ? relationship?.title : otherPartyProfile?.displayName}
              </Typography>

              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ letterSpacing: '0.4px' }}
              >
                {isGroup
                  ? interpolate(
                      baseLanguage?.dashboard?.program_memberships
                        ?.with_program_memberships?.membership_details
                        ?.creation_date,
                      {
                        date: format(
                          new Date(relationship.createdAt ?? ''),
                          'EEE do MMM',
                        ),
                      },
                    )
                  : `${roleLabel} ${baseLanguage?.relationships
                      ?.relationships_header?.since}${format(
                      parseISO(relationshipCreatedAt.toString()),
                      ' LLL yyyy',
                      {
                        locale,
                      },
                    )} `}
              </Typography>
            </>
          )}
        </Box>
      );
    }
  };

  const handleConnect = async () => {
    if (!selectedProfileId) return;

    const isGuide = groupMentor?.profile?.id === profile?.id;

    const result = await reqRelationships({
      method: 'POST',
      url: '/relationships',
      // @ts-ignore
      data: {
        guideProfiles: [groupMentor?.profile?.id],
        traineeProfiles: [isGuide ? selectedProfileId : profile?.id],
        organizationSlug,
        programTypeSlug:
          program?.program_details?.program_type?.metadata?.id?.current,
        programVariationTypeSlug: EProgramVariation.Individual,
        profileId: isGuide ? selectedProfileId : profile?.id,
        goals: [],
      },
    });
    if (result.status === 'success' && result.data) {
      const relationshipId = (result?.data as IRelationship[])[0]?.id;
      navigate(`/relationships/${relationshipId}`);
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: result?.message,
          timeout: 5000,
        }),
      );
    }
  };

  return (
    <>
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <Paper
          elevation={1}
          square
          sx={{
            borderTop: `1px solid ${combinedPalette.divider}`,
            py: 1.5,
            px: isMobile ? 2 : 3,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            zIndex: 10,
            height: isMobile ? '75px' : '81px',
          }}
        >
          {isMobile && !isSidebarOpen && (
            <IconButton
              sx={{ ml: -0.5, mr: 1.5 }}
              onClick={() => navigate('/relationships')}
              variant={'regular'}
              icon={<ArrowBackIosNew />}
            />
          )}

          {!isGroup && (
            <Stack direction={'row'} alignItems={'center'} width={'100%'}>
              <Avatar
                variant="rounded"
                src={getProfileImage({
                  profilePicture: otherPartyProfile?.profilePicture,
                  userPicture: otherPartyProfile?.userPicture,
                })}
                sx={{
                  width: isMobile ? '32px' : '49px',
                  height: isMobile ? '32px' : '49px',
                  mr: 1.5,
                  display: isMobile && isSidebarOpen ? 'none' : 'flex',
                }}
              />
              {renderContent()}
              {isMobile ? (
                <Stack direction={'row'} gap={2}>
                  <RelationshipConclude relationship={relationship} />
                  <IconButton
                    rounded
                    color="info"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    variant="contained"
                    icon={
                      isSidebarOpen ? (
                        <Close
                          sx={{ color: combinedPalette.info.contrastText }}
                        />
                      ) : (
                        <MenuOpen
                          sx={{ color: combinedPalette.info.contrastText }}
                        />
                      )
                    }
                  />
                </Stack>
              ) : (
                <>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                  >
                    <RelationshipConclude relationship={relationship} />
                  </Box>

                  <Box width={'320px'}>
                    <Text
                      variant="body1"
                      sx={{
                        pl: 6,
                        fontWeight: 500,
                      }}
                      text={programName ?? ''}
                    />
                  </Box>
                </>
              )}
            </Stack>
          )}
          {isGroup && (
            <Stack direction={'row'} alignItems={'center'} width={'100%'}>
              <GroupAvatar
                width={isMobile ? 32 : 49}
                height={isMobile ? 32 : 49}
                sx={{
                  mr: 1.5,
                  display: isMobile && isSidebarOpen ? 'none' : 'flex',
                }}
              />
              {renderContent()}

              {isMobile ? (
                <Stack direction={'row'} gap={2}>
                  <RelationshipConclude relationship={relationship} />
                  <IconButton
                    rounded
                    color="info"
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    variant="contained"
                    icon={
                      isSidebarOpen ? (
                        <Close
                          sx={{ color: combinedPalette.info.contrastText }}
                        />
                      ) : (
                        <MenuOpen
                          sx={{ color: combinedPalette.info.contrastText }}
                        />
                      )
                    }
                  />
                </Stack>
              ) : (
                <>
                  <Box
                    pr={2}
                    flex={1}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                  >
                    <MultiAvatarButton
                      avatarSrcArray={avatars}
                      avatarHeight={29}
                      avatarWidth={29}
                      textSize="xs"
                      heading=""
                      subtitles={[
                        (
                          (relationship.traineeProfiles?.length ?? 0) + 1
                        ).toString(),
                      ]}
                      sx={{
                        border: '1px solid #E0E0E0',
                        borderRadius: '4px',
                        py: '4px',
                        pl: '4px',
                      }}
                      onClick={() =>
                        setGroupMembersModalOpen(!groupMembersModalOpen)
                      }
                    />
                  </Box>
                  <RelationshipConclude relationship={relationship} />

                  <Text
                    variant="body1"
                    sx={{
                      width: '320px',
                      pl: 6,
                      fontWeight: 500,
                    }}
                    text={programName ?? ''}
                  />
                </>
              )}
            </Stack>
          )}
        </Paper>
      </ThemeProvider>
      {relationship && (
        <RelationshipViewProfileModal
          isOpen={!!selectedUserId}
          handleClose={handleProfileModalClose}
          title={modalTitle}
          closeButtonLabel={modalCloseButtonLabel}
          closeButtonAction={handleProfileModalClose}
          programSlug={relationship.programSlug ?? ''}
          userRole={getSelectedRelationshipRole(selectedUserId)}
          profileData={getSelectedProfileData(selectedUserId)}
          membershipData={getSelectedProfileMembershipData(selectedUserId)}
          connectAction={
            getSelectedRelationshipRole(selectedUserId) !== ownRole
              ? handleConnect
              : undefined
          }
        />
      )}

      {isGroup && relationship && (
        <RelationshipGroupMembersModal
          handleClose={handleGroupMembersModalClose}
          isModalOpen={groupMembersModalOpen}
          title={relationship.title ?? ''}
          actions={[
            {
              label: modalCloseButtonLabel,
              action: handleGroupMembersModalClose,
              color: 'info',
              variant: 'contained',
            },
          ]}
        >
          <Typography py={1}>{'Members'}</Typography>
          {relationship.guideProfiles?.map((guideProfile) => {
            const guideUser = relationship.users?.find(
              (user) => guideProfile.userId,
            );
            return (
              <Button
                key={`${guideProfile.id}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 1.5,
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  '&.MuiButton-root:hover': {
                    backgroundColor: combinedPalette.background.default,
                  },
                  py: 1.5,
                  px: 1,
                }}
                variant="text"
                onClick={() =>
                  handleProfileModalOpen(guideProfile?.userId, guideProfile?.id)
                }
              >
                <Avatar
                  src={getProfileImage({
                    profilePicture: guideProfile.picture,
                    userPicture: guideUser?.picture,
                  })}
                  sx={{
                    width: 29,
                    height: 29,
                  }}
                />
                <Text text={guideProfile.displayName} variant="subtitle2" />
                <Chip
                  label={programTypeGuideNoun}
                  sx={{
                    color: combinedPalette.info.main,
                    backgroundColor: `rgba(${hexToRGB(
                      combinedPalette.info.main,
                    )}, 0.08)`,
                    fontSize: '13px',
                    height: '20px',
                  }}
                />
              </Button>
            );
          })}

          {relationship.traineeProfiles?.map((traineeProfile) => {
            const traineeUser = relationship.users?.find(
              (user) => traineeProfile.userId === user.id,
            );

            return (
              <Button
                key={`${traineeProfile.id}`}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 1.5,
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                  py: 1.5,
                  px: 1,
                  width: '100%',
                  '&.MuiButton-root:hover': {
                    backgroundColor: combinedPalette.background.default,
                  },
                }}
                variant="text"
                onClick={() =>
                  handleProfileModalOpen(
                    traineeProfile.userId,
                    traineeProfile?.id,
                  )
                }
              >
                <Avatar
                  src={getProfileImage({
                    profilePicture: traineeProfile.picture,
                    userPicture: traineeUser?.picture,
                  })}
                  sx={{
                    width: 29,
                    height: 29,
                  }}
                />
                <Text text={traineeProfile.displayName} variant="subtitle2" />
              </Button>
            );
          })}
        </RelationshipGroupMembersModal>
      )}
    </>
  );
};

export default RelationshipHeader;
