// external
import React from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization, useNotes } from 'hooks';

// types
import { IRelationship, SlateDescendant } from '@guider-global/shared-types';

import { Box } from '@mui/material';
import { ActionButton, IButtonAction } from 'components/ActionButton';
import { NoteFormField, NotesCreateForm } from 'forms/Notes/NotesCreateForm';
import { emptyInitialValue } from 'components/FormRichText';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useRelationships } from '@guider-global/front-end-hooks';

export interface INoteInputs {
  [NoteFormField.Title]: string;
  [NoteFormField.Content]: SlateDescendant[];
  [NoteFormField.IsPublic]: boolean;
}

interface IRelationshipNotesCreateProps {
  relationship: IRelationship;
}

export const RelationshipNotesCreate: React.FC<
  IRelationshipNotesCreateProps
> = ({ relationship }) => {
  //hooks
  const navigate = useNavigate();
  const organizationSlug = getSubDomain();
  const { isLoadingNotes, reqNotes } = useNotes({});

  const { isLoadingRelationships, reqRelationships } = useRelationships({});

  const isLoading = isLoadingNotes || isLoadingRelationships;

  //baseLanguage
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  //form
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<INoteInputs>({
    mode: 'onChange',
    defaultValues: {
      [NoteFormField.Title]: '',
      [NoteFormField.Content]: emptyInitialValue,
      [NoteFormField.IsPublic]: false,
    },
  });
  const typedControl = control as unknown as Control;

  //handlers
  const onSubmit: SubmitHandler<INoteInputs> = async (data) => {
    const { isPublic, ...rest } = data;

    const { data: notesCreateResponseData } = await reqNotes({
      method: 'POST',
      url: `/notes`,
      data: {
        ...rest,
        isPrivate: !isPublic,
        organizationSlug,
        relationshipId: relationship.id,
      },
    });

    await Promise.all([
      reqNotes({ url: `/notes?organizationSlug=${organizationSlug}` }),
      reqRelationships({ url: '/relationships' }),
    ]);

    if (!notesCreateResponseData) {
      console.error('Something went wrong');
      return;
    }

    navigate(
      `/relationships/${relationship.id}/notes/${notesCreateResponseData[0].id}`,
    );
  };

  //modal actions
  const saveAction: IButtonAction = {
    label:
      baseLanguage?.relationships?.notes?.notes_modal
        ?.notes_modal_create_edit_note?.save_note_button_label,
    variant: 'contained',
    color: 'info',
    action: handleSubmit(onSubmit),
    isLoadingButton: true,
    disabled: !isDirty,
    dataCyLabel: 'relationship-notes-modal-create-note-button',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
        width: '100%',
        p: 2,
        pt: 0,
      }}
      data-cy="relationship-notes-modal-note-form"
    >
      <NotesCreateForm
        handleSubmit={handleSubmit(onSubmit)}
        control={typedControl}
        errors={errors}
        reset={reset}
      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <ActionButton
          isLoading={isLoading}
          buttonAction={saveAction}
          key={`modal-button-${saveAction.label}`}
        />
      </Box>
    </Box>
  );
};
