import { FC, useEffect, useMemo, useState } from 'react';
import { ChatMessageReceivedEvent } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import {
  createStatefulChatClient,
  StatefulChatClient,
} from '@azure/communication-react';
import { useNavigate } from 'react-router-dom';

import { RelationshipsPage } from 'pages';

import { useChatToken } from 'hooks';

import { useAppDispatch } from 'store/hooks';
import {
  setIsUnreadMessages,
  IRelationshipThreads,
  updateRelationshipThreads,
  IRelationshipThread,
} from 'store/slices/relationshipThreadsSlice';

import { IError } from '@guider-global/shared-types';

import { datadogLogs } from '@guider-global/datadog';
import { useChatParticipants } from 'hooks/useChatParticipants';
import { showAppAlert } from 'store/slices/appSlice';
import { useProfiles, useRelationships } from '@guider-global/front-end-hooks';

interface RelationshipsWrapperProps {
  getRelationshipSilently?: boolean;
  getProfilesSilently?: boolean;
}

const { REACT_APP_AZURE_COMMUNICATION_ENDPOINT_URL } = process.env;

export const RelationshipsWrapper: FC<RelationshipsWrapperProps> = ({
  getRelationshipSilently = true,
  getProfilesSilently = false,
}) => {
  // Router
  const navigate = useNavigate();
  // Redux
  const dispatch = useAppDispatch();
  // Local state
  const [error, setError] = useState<IError>();
  const [credential, setCredential] =
    useState<AzureCommunicationTokenCredential>();
  const [statefulChatClient, setStatefulChatClient] =
    useState<StatefulChatClient>();

  // Internal Hooks
  // Relationships
  const { relationships, errorsRelationships, isErrorRelationships } =
    useRelationships({});

  const memoRelationships = useMemo(() => relationships, [relationships]);

  // Profile
  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);
  const profileId = profile?.id ?? '';
  const communicationUserId = profile?.communicationUserId ?? '';
  const displayName = profile?.displayName ?? '';
  // Chat token
  const { token, error: chatTokenError } = useChatToken(profileId);
  // Chats
  const { chatParticipants } = useChatParticipants({});

  useEffect(() => {
    if (!token) return;

    try {
      const getCredential = new AzureCommunicationTokenCredential(token);
      setCredential(getCredential);
    } catch (error) {
      datadogLogs.logger.error('RelationshipWrapper:getCredential:', {
        error,
      });
      if (error instanceof Error) {
        setError({ message: error?.message, code: error?.name });
      } else {
        setError({
          message: 'Failed to construct token credential',
          code: 'unknown error',
        });
      }
      console.error('Failed to construct token credential');
      return undefined;
    }
  }, [token]);

  useEffect(() => {
    if (!credential) return;
    const endpoint = REACT_APP_AZURE_COMMUNICATION_ENDPOINT_URL;
    if (!endpoint) return;
    const getStatefulChatClient = createStatefulChatClient({
      userId: { communicationUserId },
      displayName: displayName,
      endpoint,
      credential,
    });

    setStatefulChatClient(getStatefulChatClient);
  }, [communicationUserId, credential, displayName]);

  useEffect(() => {
    if (!statefulChatClient) return;
    const startRealtimeNotifications = async () => {
      try {
        await statefulChatClient.startRealtimeNotifications();
      } catch (err) {
        datadogLogs.logger.error(
          'RelationshipWrapper:startRealtimeNotifications',
          {
            err,
          },
        );
        console.error(err);
        if (err instanceof Error) {
          setError({ message: err?.message, code: err?.name });
        } else {
          setError({
            message: 'Error Occurred starting Real time notifications',
            code: 'unknown error',
          });
        }
      }
    };

    const threads = memoRelationships?.map(
      (relationship): IRelationshipThread => {
        const threadId = relationship.threadId ?? '';
        const relationshipId = relationship.id;
        const isConcluded = relationship.isConcluded;
        const isUnreadMessages =
          chatParticipants?.find(
            (participant) => participant.threadId === threadId,
          )?.isUnreadMessages ?? false;
        return {
          id: relationshipId,
          threadId,
          isConcluded,
          isUnreadMessages,
        };
      },
    );
    const relationshipThreads = {
      threads,
      statefulChatClient,
    } as IRelationshipThreads;

    dispatch(updateRelationshipThreads(relationshipThreads));

    startRealtimeNotifications();

    const onChatMessageReceived = ({
      threadId,
      sender,
    }: ChatMessageReceivedEvent) => {
      if (
        sender.kind === 'communicationUser' &&
        sender.communicationUserId !== communicationUserId
      ) {
        dispatch(setIsUnreadMessages({ threadId }));
      }
    };

    statefulChatClient.on('chatMessageReceived', onChatMessageReceived);
    return () => {
      statefulChatClient.off('chatMessageReceived', onChatMessageReceived);
    };
  }, [
    chatParticipants,
    communicationUserId,
    dispatch,
    memoRelationships,
    statefulChatClient,
  ]);

  useEffect(() => {
    const handleDispatchedError = (
      errorCode?: string,
      errorMessage?: string,
    ) => {
      const code = errorCode ?? 'Undefined Error Code';
      const message = errorMessage ?? 'Undefined Error Messsage';
      console.error('error', { code, message });
      datadogLogs.logger.error('RelationshipWrapper:', { code, message });
      dispatch(
        showAppAlert({
          severity: 'error',
          message: message,
          timeout: 10000,
        }),
      );
    };
    if (isErrorRelationships) {
      const errorCode = errorsRelationships?.code;
      handleDispatchedError(errorCode, errorsRelationships?.message);
    }
    if (chatTokenError) {
      const errorCode = chatTokenError.code?.toString();
      handleDispatchedError(errorCode, chatTokenError.message);
    }
    if (error?.message) {
      const errorCode = error.code?.toString();
      handleDispatchedError(errorCode, error.message);
    }
  }, [
    chatTokenError,
    dispatch,
    error,
    errorsRelationships,
    isErrorRelationships,
    navigate,
  ]);

  return <RelationshipsPage />;
};
