// external
import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { ModalCard } from 'components';
import { GoalsDetails } from 'components/Goals/GoalsDetails';
import { ConfirmationModal } from 'modals';

// hooks
import { useGoals, useLocalization, useRelationships } from 'hooks';

// types

import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { IGoal, IRelationship } from '@guider-global/shared-types';
import { IButtonAction } from 'components/ActionButton';

interface IRelationshipGoalsViewProps {
  relationship: IRelationship;
  handleClose: () => void;
  isGuide: boolean;
}

export const RelationshipGoalsView: React.FC<IRelationshipGoalsViewProps> = ({
  relationship,
  handleClose,
  isGuide,
}) => {
  //state
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  //hooks
  const organizationSlug = getSubDomain();

  const navigate = useNavigate();
  const { reqRelationships } = useRelationships({ getSilently: false });

  //style
  const theme = useTheme();

  //base language
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const backButtonLabel =
    baseLanguage?.globals?.common?.go_back_button_label ?? 'Go back';

  const closeButtonLabel =
    baseLanguage?.globals?.common?.close_button_label ?? 'Close';

  //goals
  const { goalId } = useParams();
  const { reqGoals, goals } = useGoals({});
  const relationshipGoals = relationship?.goals as IGoal[];
  const goal =
    relationshipGoals.find((goal) => goal.id === goalId) ||
    goals?.find((goal) => goal.id === goalId);

  const traineeProfiles = relationship.traineeProfiles;
  const traineeProfile = traineeProfiles?.find(
    (profile) => profile.userId === goal?.userId,
  );

  if (!goal) {
    return <></>;
  }

  //handlers

  const handleDeleteGoal = async () => {
    await reqGoals({
      method: 'DELETE',
      url: `/goals/${goalId}?relationshipId=${relationship.id}`,
    });

    await Promise.all([
      reqGoals({ url: '/goals' }),
      reqRelationships({ url: '/relationships' }),
    ]);

    navigate(`/relationships/${relationship.id}/goals`);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  //modal actions

  const guideModalActions: IButtonAction[] = [
    {
      label: closeButtonLabel,
      color: 'info',
      variant: 'contained',
      action: () => handleClose(),
    },
  ];

  const traineeModalActions: (IButtonAction | IButtonAction[])[] = [
    {
      label: baseLanguage?.globals?.common?.delete_button_label ?? 'Delete',
      action: async () => {
        setIsDeleteModalOpen(true);
      },
      variant: 'text',
      color: 'error',
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsView_delete-button',
    },
    [
      {
        label: backButtonLabel,
        color: 'info',
        variant: 'outlined',
        action: () => navigate(`/relationships/${relationship.id}/goals`),
        dataCyLabel:
          'components_Relationships_RelationshipGoals_RelationshipGoalsView_close-button',
      },
      {
        label:
          baseLanguage?.globals?.goals?.edit_goal_button_label ?? 'Edit Goal',
        variant: 'contained',
        action: () =>
          navigate(`/relationships/${relationship.id}/goals/${goal.id}/edit`),
        color: 'info',
        dataCyLabel:
          'components_Relationships_RelationshipGoals_RelationshipGoalsView_edit-button',
      },
    ],
  ];

  const deleteModalActions: IButtonAction[] = [
    {
      label: baseLanguage?.globals?.common?.cancel_button_label ?? 'Cancel',
      action: handleDeleteModalClose,
      color: 'info',
      variant: 'outlined',
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsView_discard-delete-button',
    },
    {
      label: baseLanguage?.globals?.common?.delete_button_label,
      action: () => {
        handleDeleteGoal();
      },
      color: 'error',
      variant: 'contained',
      isLoadingButton: true,
      dataCyLabel:
        'components_Relationships_RelationshipGoals_RelationshipGoalsView_confirm-delete-button',
    },
  ];

  return (
    <ModalCard
      title={baseLanguage?.relationships?.goals?.view_goal_modal?.title}
      handleClose={handleClose}
      actions={isGuide ? guideModalActions : traineeModalActions}
      childrenSx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        overflowY: 'auto',
        px: 2,
        py: 3,
      }}
    >
      <GoalsDetails
        goal={goal}
        traineeProfile={traineeProfile}
        relationship={relationship}
      />
      <ConfirmationModal
        handleClose={handleDeleteModalClose}
        open={isDeleteModalOpen}
        title={baseLanguage?.relationships?.goals?.delete_goal_modal?.title}
        description={
          baseLanguage?.relationships?.goals?.delete_goal_modal?.description
        }
        actions={deleteModalActions}
      />
    </ModalCard>
  );
};
